import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AppNavbar from "./components/navbar/navabr";
import CarousalSlide from "./components/carousalSlide/carousalSlide";
import Services from "./components/services/services";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import MeetFollow from "./components/meetFollow/meetFollow";

function App() {
  return (
    <div className="App">
      <AppNavbar />
      <CarousalSlide/>
      <Services />
      {/* <Banner />
      <Global />
      <BannerTwo />
      <SupportCard />
      <BannerThree /> */}
      <About />
      <Contact />
      <MeetFollow />
    </div>
  );
}

export default App;
