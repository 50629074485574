
import { Container} from "react-bootstrap";
import './about.css'


function About () {
  return (
    <section id="about" data-section>
    <Container style={{background:" #F3F3F3"}} fluid>
      <Container>
          <div className="about-div">About Us</div>
          <p className="about-cont">
            As a leading marketing firm, we specialise in providing
            comprehensive solutions for a diverse range of event-related needs,
            including exhibitions, advertising initiatives, and promotional
            campaigns. Our extensive services encompass everything from staffing
            solutions with temporary and permanent promoters, hostesses, and
            merchandisers to strategic brand development tailored to corporate
            objectives. At the core of our approach lies a deep commitment to
            delivering exceptional results for our clients. We leverage our
            expertise and industry insights to develop strategic branding
            strategies that enable businesses to effectively engage with their
            target audiences and drive sustainable growth. From devising
            innovative marketing campaigns to implementing impactful branding
            initiatives, we work closely with our clients to achieve their goals
            with precision and efficiency. Our team of seasoned professionals is
            dedicated to providing unparalleled service and support, ensuring
            that every project is executed flawlessly and exceeds expectations.
            By combining creativity, expertise, and a customer-centric approach,
            we empower our clients to elevate their brand presence, maximize
            their event impact, and achieve lasting success in their respective
            industries. Partner with us today to unlock the full potential of
            your events and brands, and experience the difference of working
            with a trusted marketing partner committed to your success.
          </p>
      </Container>
    </Container>
    </section>
  );
}

export default About;
