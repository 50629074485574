import { Col, Row, Container, Image } from "react-bootstrap";
import ContactImage from "./../../assets/contact-img.svg";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import "./contact.css";
import { useState } from "react";

const Contact = () => {
  const [values, setValues] = useState({
    name: "",
    phone: "",
    reason: "Select Reason",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    reason: "",
    message: "",
  });
  const reasonArray = ["Select Reason", "Promotion", "Event Management", "Anchoring"];

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      name: "",
      phone: "",
      reason: "",
      message: "",
    };

    if (!values.name.trim()) {
      newErrors.name = "Name is required";
      valid = false;
    }
    if (!values.phone.trim()) {
      newErrors.phone = "Phone number is required";
      valid = false;
    } else if (!/^\d{10}$/.test(values.phone.trim())) {
      newErrors.phone = "Invalid phone number";
      valid = false;
    }
    if (values.reason === "Select Reason") {
      newErrors.reason = "Please select a reason";
      valid = false;
    }
    if (!values.message.trim()) {
      newErrors.message = "Message is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const generateWhatsAppLink = () => {
    const { name, phone, reason, message } = values;
    const encodedMessage = `Hi, I'm interested in ${reason}. My name is ${name} and my phone number is ${phone}. ${message}`;
    const encodedLink = `https://api.whatsapp.com/send?phone=971504915319&text=`+encodedMessage;
    console.log(encodedLink)
    return encodedLink;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      window.open(generateWhatsAppLink());
      
      console.log("Form submitted:", values);
    } else {
      console.log("Form validation failed");
    }
  };

  return (
    <section id="contact" data-section>
      <Container>
      <div className="contact-div">Contact Us</div>
        <Row>
          <Col lg={6} className="contact-img-container">
            <Image src={ContactImage} />
            <div className="text-center contact-p">
              <div>For further inquiries, please don't <br></br> hesitate to get in touch with us.</div>
            </div>
          </Col>

          <Col lg={6}>
            <div className="contact-form">
              <TextField
                name="name"
                id="name"
                label="Name"
                variant="filled"
                className="contact-form-textfield"
                InputProps={{ disableUnderline: true }}
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
              />
              <TextField
                name="phone"
                id="phone"
                label="Phone"
                variant="filled"
                className="contact-form-textfield"
                InputProps={{ disableUnderline: true }}
                onChange={handleChange}
                error={!!errors.phone}
                helperText={errors.phone}
              />
              <Select
                name="reason"
                disableUnderline
                label="Requirements"
                labelId="reason-label"
                id="reason"
                className="contact-form-select"
                variant="filled"
                value={values.reason}
                onChange={handleChange}
                error={!!errors.reason}
              >
                {reasonArray.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              {errors.reason && <div className="error-message">{errors.reason}</div>}
              <TextField
                name="message"
                id="message"
                label="Message"
                multiline
                rows={4}
                InputProps={{ disableUnderline: true }}
                className="contact-form-textfield"
                variant="filled"
                onChange={handleChange}
                error={!!errors.message}
                helperText={errors.message}
              />
              <Button className="contact-form-button" variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contact;
