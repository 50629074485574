import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ServiceImg from "./../../assets/service-img.png";
import GlobalImg from "./../../assets/global-img.png";
import FrameOne from "./../../assets/Frame-one.png";
import FrameTwo from "./../../assets/Frame-two.png";
import FrameThree from "./../../assets/Frame-three.png";
import BarImg from './../../assets/bars.svg';
import "./services.css";

function Services() {
  return (
    <section id="service" data-section>
      
      <Container>
        <Row className=" main-container">
            <div className="service-container">Services</div>
          <Col lg={5}>
          <h3 className="service-h">Product Promotion</h3>
            <div className="paragraph-container">
              <p>
                As a leading integrated marketing agency, we specialize in
                providing comprehensive solutions for all your event-related
                needs. From meticulously planned exhibitions to strategic
                advertising campaigns and impactful promotional activities, we
                offer a diverse range of services tailored to elevate your brand
                presence and drive engagement.
              </p>
              <p>
                Our team of seasoned professionals is dedicated to delivering
                excellence in every aspect of your marketing journey. Whether
                you require temporary staffing for a specific event or seek
                long-term promotional support, we have you covered. Our
                extensive network of skilled promoters, experienced hostesses,
                and adept merchandisers ensures that your brand message is
                effectively communicated and resonates with your target
                audience.
              </p>
              <p>
                With a keen understanding of market dynamics and consumer
                behavior, we craft bespoke strategies that align with your brand
                objectives and deliver measurable results. Partner with us to
                unlock the full potential of your marketing initiatives and
                leave a lasting impression on your audience.
              </p>
            </div>
          </Col>
          <Col lg={7}>
            <div>
              <Image src={ServiceImg} fluid />
            </div>
          </Col>
        </Row>
      </Container>

      {/* bannerone */}
      <Container className="banner-container" fluid>
      <div className="banner-para-div">
        <p>
          We eagerly await the opportunity to address any questions or concerns
          you may have and to provide further information about our services.
        </p>
        <a className="banner-link" href="#contact">Contact Us</a>
      </div>
    </Container>

    {/* global */}

    <Container fluid className="global-main">
      <Row>
        <Col lg={12} className="text-center">
          <h3 className="global-title">Get closure to your</h3>
          <h1 className="global-second-title">NEXT GLOBAL Opportunity</h1>
          {/* <Image src={Vector} className="vector"/> */}
        </Col>
        <Col lg={12} className="d-flex justify-content-center ">
          <Image src={GlobalImg} fluid />
        </Col>
      </Row>
    </Container>

    {/* banner two */}

    <Container fluid className="bannertwo-img">
        
    </Container>

    {/* support card */}


        <Container>
      <Row>
        <Col lg={12} className="text-center">
          <h3 className="card-title">We are there for you</h3>
        </Col>
        <Col  className="d-flex justify-content-center ">
          
          <div className="support-elements">
            <div className="support-img">
              <img src={FrameOne} alt="" />
              <div className="support-text">Celebrating excellence <br/> together</div>
            </div>
            <div className="support-img">
              <img src={FrameTwo} alt="" />
              <div className="support-text">The perfect budgeting <br /> recipe</div>
            </div>
            <div className="support-img">
              <img src={FrameThree} alt="" />
              <div className="support-text">Get closure to your</div>
            </div>
          </div>
          <div className="support-container">
          </div>
        </Col>
      </Row>
    </Container>

    {/* banner three */}

    <Container className='bg-img-container' fluid>
    <Row>
      <Col lg={6}>
        <div className="banner-three-text">
          <h3>GROW YOUR BUSINESS <br></br> WITH US</h3>
          <a href='#contact'>Connect with Us !</a>
        </div>
      </Col>
      <Col lg={6}>
        <div className="banner-three-bar-img">
          <Image src={BarImg}  />
        </div>
      </Col>
    </Row>
    </Container>

    </section>
  );
}

export default Services;
