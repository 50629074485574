import React from "react";
import "./meetFollow.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";

function MeetFollow() {
  return (
    <section>
      <Container className="meet-follow" fluid>
        <Row>
          <Col lg={6} className="meet-us">
            <div className="head">Meet Us</div>

            <div className="data-div">
              <BsFillTelephoneFill className="icons" />
              <div className="link-container">
                <a href="tel:+971504915319"> +971504915319</a> | <a href="tel:+971504942319"> +971504942319</a>
              </div>
            </div>

            <div className="data-div">
              <IoMail className="icons" />
              <div className="link-container">
                info@uyooneljedid.ae, sales@uyooneljedid.ae
              </div>
            </div>

            <div className="data-div">
              <FaLocationDot className="icons" />
              <div className="link-container">
                AI Qusais, Dubai-UAE
              </div>
            </div>

          </Col>
          <Col lg={6} className="follow-us">
            <div className="head">Follow Us</div>

            <div className="data-div">
              <FaFacebook className="icons" />
              <div className="link-container">
                <a href="https://www.facebook.com/profile.php?id=61557367043235&mibextid=ZbWKwL">
                  Facebook
                </a>
              </div>
            </div>
            <div className="data-div">
              <FaInstagram className="icons" />
              <div className="link-container">
                <a href="https://www.instagram.com/uyooneljedidpromotions?igsh=c3NuanRjMXcyYWkz">
                  Instagram
                </a>
              </div>
            </div>
            <div className="data-div">
              <IoLogoLinkedin className="icons" />
              <div className="link-container">
                <a href="https://www.linkedin.com/in/uyoon-eljedid-11b0592b9?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                  LinkedIn
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="footer-div">
              All right reserved
              <span className="footer-span">Uyoon Eljedid</span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default MeetFollow;
