
import { Image } from 'react-bootstrap';
import './carousalSlide.css';
import Banner from './../../assets/BANNER.png';

function CarousalSlide () {
  return (
    <section id='home' data-section>
    <div>
    <Image src={Banner} className='corousal-img'  fluid/>
    </div>
    </section>
  );
}

export default CarousalSlide;
